export enum Product {
    Account = 'Account',
    Mail = 'Mail',
    Calendar = 'Calendar',
    Wallet = 'Wallet',
    Docs = 'Docs',
    Lumo = 'Lumo',
    Drive = 'Drive',
    VPN = 'VPN',
    Verify = 'VPN',
    Admin = 'Admin',
    Pass = 'Pass',
    Other = 'Other',
}
