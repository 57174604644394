import { useState } from 'react';

import generateUID from '@proton/utils/generateUID';

import LogoBase, { type LogoProps } from './LogoBase';

const VpnForBusinessLogo = ({ variant = 'with-wordmark', hasTitle, ...rest }: LogoProps) => {
    const [uid] = useState(generateUID('logo'));

    const logoWidth = 249;
    const logoHeight = 48;

    return (
        <LogoBase uid={uid} logoWidth={logoWidth} logoHeight={logoHeight} title={undefined} variant={variant} {...rest}>
            <g clipPath="url(#clip0_631_54368)">
                <path
                    d="M0 28.0379V32.9245H3.41208V28.2513C3.41208 27.7977 3.59027 27.3594 3.91252 27.0392C4.23098 26.719 4.66697 26.536 5.11812 26.536H8.6174C10.2476 26.536 11.8134 25.8842 12.9659 24.7216C14.1184 23.5629 14.7667 21.9886 14.7667 20.3496C14.7667 18.7105 14.1184 17.1363 12.9659 15.9737C11.8134 14.8149 10.2476 14.1631 8.61361 14.1631H0V20.2695H3.41208V17.3916H8.38613C9.15954 17.3916 9.89882 17.7004 10.4448 18.2493C10.9907 18.7982 11.2978 19.5415 11.2978 20.3191C11.2978 21.0967 10.9907 21.84 10.4448 22.3889C9.89882 22.9377 9.15954 23.2465 8.38613 23.2465H4.77312C4.14757 23.2465 3.52582 23.3685 2.94955 23.6124C2.3695 23.8526 1.84631 24.2071 1.40274 24.653C0.959173 25.099 0.610386 25.6288 0.367749 26.2082C0.125112 26.7838 0 27.4089 0 28.0379Z"
                    fill="currentColor"
                />
                <path
                    d="M15.2937 32.9245V25.4802C15.2937 22.4422 17.0566 20.0256 20.5862 20.0256C21.1511 20.0179 21.716 20.0789 22.2695 20.2123V23.2732C21.8677 23.2465 21.5227 23.2465 21.3596 23.2465C19.4906 23.2465 18.6868 24.1079 18.6868 25.8537V32.9245H15.2937Z"
                    fill="currentColor"
                />
                <path
                    d="M23.2854 26.6123C23.2854 22.8768 26.0909 20.0294 29.9959 20.0294C33.9008 20.0294 36.7063 22.8768 36.7063 26.6123C36.7063 30.3478 33.9008 33.2219 29.9959 33.2219C26.0909 33.2219 23.2854 30.344 23.2854 26.6123ZM33.3625 26.6123C33.3625 24.4892 31.9445 22.9835 29.9959 22.9835C28.0434 22.9835 26.6293 24.4854 26.6293 26.6123C26.6293 28.7622 28.0472 30.2411 29.9959 30.2411C31.9483 30.2411 33.3625 28.7583 33.3625 26.6123Z"
                    fill="currentColor"
                />
                <path
                    d="M47.5568 26.6123C47.5568 22.8768 50.3623 20.0294 54.2672 20.0294C58.1684 20.0294 60.9738 22.8768 60.9738 26.6123C60.9738 30.3478 58.1684 33.2219 54.2672 33.2219C50.3623 33.2219 47.5568 30.344 47.5568 26.6123ZM57.63 26.6123C57.63 24.4892 56.2121 22.9835 54.2634 22.9835C52.3148 22.9835 50.8969 24.4854 50.8969 26.6123C50.8969 28.7622 52.3148 30.2411 54.2634 30.2411C56.2121 30.2411 57.63 28.7583 57.63 26.6123Z"
                    fill="currentColor"
                />
                <path
                    d="M62.7368 32.9246V25.7509C62.7368 22.4194 64.8485 20.0256 68.617 20.0256C72.3589 20.0256 74.4706 22.4156 74.4706 25.7509V32.9246H71.104V26.0177C71.104 24.1652 70.2737 23.0064 68.617 23.0064C66.9602 23.0064 66.1299 24.1614 66.1299 26.0177V32.9246H62.7368Z"
                    fill="currentColor"
                />
                <path
                    d="M46.5408 23.01H42.8785V27.7137C42.8785 29.3528 43.4661 30.1037 45.1494 30.1037C45.3086 30.1037 45.7105 30.1037 46.2185 30.077V32.8444C45.5247 33.0311 44.9105 33.1417 44.2395 33.1417C41.4075 33.1417 39.4815 31.4226 39.4815 28.1711V23.01H37.2068V20.2961H37.7755C37.9992 20.2961 38.2229 20.2503 38.4276 20.1665C38.6361 20.0788 38.8219 19.953 38.9811 19.7929C39.1403 19.6328 39.2654 19.446 39.3526 19.2364C39.4398 19.0267 39.4815 18.8057 39.4815 18.5808V16.0231H42.8747V20.2961H46.537V23.01H46.5408Z"
                    fill="currentColor"
                />
                <path
                    d="M109.417 14.2213H100.655V33.2219H104.126V28.492C104.126 28.0326 104.307 27.5885 104.635 27.2642C104.959 26.9399 105.402 26.7545 105.861 26.7545H109.417C111.076 26.7545 112.665 26.0943 113.833 24.9243C115.006 23.7506 115.662 22.1598 115.662 20.5034C115.665 19.6809 115.507 18.8624 115.195 18.1017C114.882 17.3411 114.424 16.6461 113.841 16.0631C113.259 15.48 112.572 15.0167 111.812 14.7001C111.056 14.3835 110.243 14.2213 109.417 14.2213ZM112.152 20.4532C112.152 20.8431 112.075 21.2293 111.924 21.5883C111.774 21.9474 111.558 22.2756 111.28 22.5498C111.006 22.8239 110.679 23.044 110.32 23.1907C109.961 23.3413 109.575 23.4146 109.186 23.4146H104.11V17.4955H109.186C109.575 17.4955 109.961 17.5728 110.324 17.7233C110.682 17.8739 111.01 18.094 111.284 18.3682C111.558 18.6462 111.778 18.9744 111.924 19.3334C112.071 19.6964 112.148 20.0825 112.144 20.4725L112.152 20.4532Z"
                    fill="currentColor"
                />
                <path
                    d="M88.463 33.1525L81.4706 14.1673H85.42L90.0212 27.7546C90.2025 28.2411 90.3336 28.743 90.4108 29.2566H90.4493C90.5072 28.7392 90.6383 28.2334 90.8389 27.7546L95.4555 14.1673H99.4319L92.4048 33.1525H88.463Z"
                    fill="currentColor"
                />
                <path
                    d="M116.961 33.1521V14.1631H120.004L128.96 25.9395C129.284 26.3449 129.562 26.7812 129.797 27.2446H129.851C129.789 26.6731 129.762 26.0978 129.766 25.5225V14.1631H133.353V33.1482H130.31L121.327 21.3718C121.011 20.9625 120.733 20.5262 120.494 20.0667H120.463C120.513 20.6382 120.533 21.2135 120.521 21.7888V33.1482H116.961V33.1521Z"
                    fill="currentColor"
                />
                <path
                    d="M144.353 33.1701V18.5H152.711V20.3553H146.293V24.9721H152.422V26.8274H146.293V33.1701H144.353Z"
                    fill="currentColor"
                />
                <path
                    d="M161.842 31.8757C160.893 32.8824 159.71 33.3858 158.293 33.3858C156.876 33.3858 155.686 32.8824 154.723 31.8757C153.774 30.8545 153.299 29.6032 153.299 28.1218C153.299 26.6405 153.774 25.3964 154.723 24.3896C155.686 23.3685 156.876 22.8579 158.293 22.8579C159.71 22.8579 160.893 23.3685 161.842 24.3896C162.805 25.3964 163.287 26.6405 163.287 28.1218C163.287 29.6032 162.805 30.8545 161.842 31.8757ZM156.064 25.6409C155.486 26.2881 155.198 27.1151 155.198 28.1218C155.198 29.1286 155.486 29.9556 156.064 30.6028C156.656 31.25 157.399 31.5736 158.293 31.5736C159.187 31.5736 159.923 31.25 160.501 30.6028C161.093 29.9556 161.388 29.1286 161.388 28.1218C161.388 27.1151 161.093 26.2881 160.501 25.6409C159.923 24.9937 159.187 24.6701 158.293 24.6701C157.399 24.6701 156.656 24.9937 156.064 25.6409Z"
                    fill="currentColor"
                />
                <path
                    d="M165.143 26.7843C165.143 25.5618 165.473 24.6053 166.134 23.915C166.794 23.2102 167.661 22.8579 168.734 22.8579C169.078 22.8579 169.429 22.9082 169.786 23.0089V24.8211C169.662 24.8067 169.415 24.7995 169.043 24.7995C168.397 24.7995 167.901 24.9865 167.558 25.3604C167.214 25.7344 167.042 26.3672 167.042 27.2589V33.1701H165.143V26.7843Z"
                    fill="currentColor"
                />
                <path
                    d="M176.617 33.1701V18.5H180.992C182.574 18.5 183.744 18.8596 184.5 19.5787C185.271 20.2978 185.656 21.283 185.656 22.5343C185.656 23.3541 185.422 24.0372 184.954 24.5838C184.5 25.1303 183.964 25.4611 183.345 25.5762V25.6193C184.211 25.7344 184.927 26.0795 185.491 26.6548C186.069 27.2301 186.357 27.9924 186.357 28.9416C186.357 30.2504 185.938 31.286 185.099 32.0482C184.259 32.7961 183.042 33.1701 181.446 33.1701H176.617ZM178.557 31.3147H181.302C183.351 31.3147 184.376 30.5237 184.376 28.9416C184.376 28.1362 184.087 27.5394 183.51 27.151C182.946 26.7627 182.058 26.5685 180.848 26.5685H178.557V31.3147ZM178.557 24.7132H180.848C181.838 24.7132 182.553 24.5406 182.994 24.1954C183.448 23.8503 183.675 23.2965 183.675 22.5343C183.675 21.0816 182.732 20.3553 180.848 20.3553H178.557V24.7132Z"
                    fill="currentColor"
                />
                <path
                    d="M195.357 32.1777C194.614 32.9831 193.617 33.3858 192.365 33.3858C191.113 33.3858 190.115 32.9831 189.372 32.1777C188.63 31.3723 188.258 30.3295 188.258 29.0495V23.0736H190.157V28.8985C190.157 29.7327 190.342 30.3799 190.714 30.8401C191.085 31.3004 191.636 31.5305 192.365 31.5305C193.094 31.5305 193.644 31.3004 194.016 30.8401C194.387 30.3799 194.573 29.7327 194.573 28.8985V23.0736H196.471V29.0495C196.471 30.3295 196.1 31.3723 195.357 32.1777Z"
                    fill="currentColor"
                />
                <path
                    d="M202.303 33.3858C201.23 33.3858 200.342 33.0838 199.641 32.4797C198.953 31.8757 198.581 31.0631 198.526 30.0419H200.425C200.48 30.5453 200.679 30.948 201.023 31.25C201.381 31.5521 201.807 31.7031 202.303 31.7031C202.812 31.7031 203.204 31.588 203.479 31.3579C203.754 31.1278 203.892 30.8473 203.892 30.5165C203.892 30.1282 203.713 29.819 203.355 29.5889C202.997 29.3444 202.564 29.1502 202.055 29.0064C201.56 28.8625 201.058 28.6899 200.549 28.4886C200.053 28.2872 199.627 27.9636 199.269 27.5178C198.911 27.0719 198.733 26.5038 198.733 25.8135C198.733 25.0224 199.028 24.3321 199.62 23.7424C200.211 23.1527 201.03 22.8579 202.076 22.8579C203.066 22.8579 203.878 23.1383 204.511 23.6992C205.144 24.2602 205.508 25.044 205.604 26.0508H203.706C203.555 25.044 203.011 24.5406 202.076 24.5406C201.663 24.5406 201.319 24.6557 201.044 24.8858C200.769 25.1159 200.631 25.4251 200.631 25.8135C200.631 26.2018 200.81 26.511 201.168 26.7411C201.525 26.9713 201.952 27.1582 202.447 27.302C202.956 27.4459 203.458 27.6185 203.954 27.8198C204.463 28.0068 204.896 28.3304 205.254 28.7906C205.611 29.2365 205.79 29.8118 205.79 30.5165C205.79 31.3507 205.46 32.0411 204.8 32.5876C204.139 33.1197 203.307 33.3858 202.303 33.3858Z"
                    fill="currentColor"
                />
                <path
                    d="M207.625 33.1701V23.0736H209.524V33.1701H207.625ZM207.687 20.7652C207.453 20.5207 207.336 20.2187 207.336 19.8591C207.336 19.4996 207.453 19.1975 207.687 18.953C207.935 18.6942 208.23 18.5647 208.574 18.5647C208.918 18.5647 209.207 18.6942 209.441 18.953C209.689 19.1975 209.813 19.4996 209.813 19.8591C209.813 20.2187 209.689 20.5207 209.441 20.7652C209.207 21.0097 208.918 21.132 208.574 21.132C208.23 21.132 207.935 21.0097 207.687 20.7652Z"
                    fill="currentColor"
                />
                <path
                    d="M212.018 27.1942C212.018 25.9141 212.396 24.8714 213.153 24.066C213.91 23.2606 214.928 22.8579 216.207 22.8579C217.487 22.8579 218.505 23.2606 219.261 24.066C220.018 24.8714 220.396 25.9141 220.396 27.1942V33.1701H218.498V27.3452C218.498 26.4966 218.305 25.8422 217.92 25.382C217.535 24.9074 216.964 24.6701 216.207 24.6701C215.45 24.6701 214.879 24.9074 214.494 25.382C214.109 25.8422 213.916 26.4966 213.916 27.3452V33.1701H212.018V27.1942Z"
                    fill="currentColor"
                />
                <path
                    d="M227.131 33.3858C225.714 33.3858 224.545 32.8968 223.623 31.9188C222.715 30.9408 222.261 29.6751 222.261 28.1218C222.261 26.5685 222.715 25.3029 223.623 24.3249C224.531 23.3469 225.7 22.8579 227.131 22.8579C228.411 22.8579 229.497 23.3685 230.392 24.3896C231.3 25.3964 231.754 26.6405 231.754 28.1218C231.754 28.4383 231.74 28.6971 231.712 28.8985H224.222C224.359 29.7183 224.689 30.3727 225.212 30.8617C225.749 31.3363 226.388 31.5736 227.131 31.5736C228.328 31.5736 229.14 31.099 229.566 30.1498H231.506C231.217 31.1278 230.674 31.9116 229.876 32.5013C229.092 33.091 228.177 33.3858 227.131 33.3858ZM224.242 27.2589H229.793C229.725 26.5542 229.442 25.9501 228.947 25.4467C228.452 24.9289 227.847 24.6701 227.131 24.6701C226.388 24.6701 225.755 24.9074 225.233 25.382C224.724 25.8422 224.393 26.4679 224.242 27.2589Z"
                    fill="currentColor"
                />
                <path
                    d="M236.965 33.3858C235.892 33.3858 235.005 33.0838 234.303 32.4797C233.615 31.8757 233.244 31.0631 233.189 30.0419H235.087C235.142 30.5453 235.342 30.948 235.686 31.25C236.043 31.5521 236.47 31.7031 236.965 31.7031C237.474 31.7031 237.866 31.588 238.141 31.3579C238.416 31.1278 238.554 30.8473 238.554 30.5165C238.554 30.1282 238.375 29.819 238.017 29.5889C237.66 29.3444 237.226 29.1502 236.717 29.0064C236.222 28.8625 235.72 28.6899 235.211 28.4886C234.716 28.2872 234.289 27.9636 233.931 27.5178C233.574 27.0719 233.395 26.5038 233.395 25.8135C233.395 25.0224 233.691 24.3321 234.282 23.7424C234.874 23.1527 235.692 22.8579 236.738 22.8579C237.729 22.8579 238.54 23.1383 239.173 23.6992C239.806 24.2602 240.171 25.044 240.267 26.0508H238.368C238.217 25.044 237.674 24.5406 236.738 24.5406C236.325 24.5406 235.981 24.6557 235.706 24.8858C235.431 25.1159 235.293 25.4251 235.293 25.8135C235.293 26.2018 235.472 26.511 235.83 26.7411C236.188 26.9713 236.614 27.1582 237.109 27.302C237.618 27.4459 238.121 27.6185 238.616 27.8198C239.125 28.0068 239.558 28.3304 239.916 28.7906C240.274 29.2365 240.453 29.8118 240.453 30.5165C240.453 31.3507 240.122 32.0411 239.462 32.5876C238.802 33.1197 237.969 33.3858 236.965 33.3858Z"
                    fill="currentColor"
                />
                <path
                    d="M245.631 33.3858C244.558 33.3858 243.67 33.0838 242.969 32.4797C242.281 31.8757 241.909 31.0631 241.854 30.0419H243.753C243.808 30.5453 244.007 30.948 244.351 31.25C244.709 31.5521 245.135 31.7031 245.631 31.7031C246.14 31.7031 246.532 31.588 246.807 31.3579C247.082 31.1278 247.22 30.8473 247.22 30.5165C247.22 30.1282 247.041 29.819 246.683 29.5889C246.325 29.3444 245.892 29.1502 245.383 29.0064C244.888 28.8625 244.386 28.6899 243.877 28.4886C243.381 28.2872 242.955 27.9636 242.597 27.5178C242.239 27.0719 242.061 26.5038 242.061 25.8135C242.061 25.0224 242.356 24.3321 242.948 23.7424C243.539 23.1527 244.358 22.8579 245.404 22.8579C246.394 22.8579 247.206 23.1383 247.839 23.6992C248.472 24.2602 248.836 25.044 248.932 26.0508H247.034C246.883 25.044 246.339 24.5406 245.404 24.5406C244.991 24.5406 244.647 24.6557 244.372 24.8858C244.097 25.1159 243.959 25.4251 243.959 25.8135C243.959 26.2018 244.138 26.511 244.496 26.7411C244.853 26.9713 245.28 27.1582 245.775 27.302C246.284 27.4459 246.786 27.6185 247.282 27.8198C247.791 28.0068 248.224 28.3304 248.582 28.7906C248.939 29.2365 249.118 29.8118 249.118 30.5165C249.118 31.3507 248.788 32.0411 248.128 32.5876C247.467 33.1197 246.635 33.3858 245.631 33.3858Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_631_54368">
                    <rect width="249" height="48" fill="currentColor" />
                </clipPath>
            </defs>
        </LogoBase>
    );
};

export default VpnForBusinessLogo;
