import { useState } from 'react';

import { PASS_APP_NAME } from '@proton/shared/lib/constants';
import generateUID from '@proton/utils/generateUID';

import LogoBase, { type LogoProps } from './LogoBase';

const PassLogo = ({ variant = 'with-wordmark', hasTitle = true, ...rest }: LogoProps) => {
    const [uid] = useState(generateUID('logo'));

    let logoWidth: number;
    const logoHeight = 36;

    switch (variant) {
        case 'glyph-only':
            logoWidth = 36;
            break;
        case 'wordmark-only':
            logoWidth = 260;
            break;
        default:
            logoWidth = 142;
            break;
    }

    return (
        <LogoBase
            uid={uid}
            logoWidth={logoWidth}
            logoHeight={logoHeight}
            title={hasTitle ? PASS_APP_NAME : undefined}
            variant={variant}
            {...rest}
        >
            {variant === 'glyph-only' && (
                <>
                    <path
                        fill={`url(#${uid}-a)`}
                        d="M12.42 7.54c1.95-1.96 2.93-2.93 4.06-3.3a4.93 4.93 0 0 1 3.04 0c1.13.37 2.1 1.34 4.06 3.3l4.88 4.88c1.96 1.95 2.93 2.93 3.3 4.06.32.99.32 2.05 0 3.04-.37 1.13-1.34 2.1-3.3 4.06l-4.88 4.88c-1.95 1.96-2.93 2.93-4.06 3.3-.99.32-2.05.32-3.04 0-1.13-.37-2.1-1.34-4.06-3.3l-.92-1.03a7.87 7.87 0 0 1-1.03-1.28 3.7 3.7 0 0 1-.38-1c-.09-.4-.09-.82-.09-1.66V12.51c0-.84 0-1.26.09-1.65.08-.35.2-.7.38-1 .2-.36.48-.67 1.03-1.3l.92-1.02Z"
                    />
                    <path
                        fill={`url(#${uid}-b)`}
                        d="M12.42 7.54c1.95-1.96 2.93-2.93 4.06-3.3a4.93 4.93 0 0 1 3.04 0c1.13.37 2.1 1.34 4.06 3.3l4.88 4.88c1.96 1.95 2.93 2.93 3.3 4.06.32.99.32 2.05 0 3.04-.37 1.13-1.34 2.1-3.3 4.06l-4.88 4.88c-1.95 1.96-2.93 2.93-4.06 3.3-.99.32-2.05.32-3.04 0-1.13-.37-2.1-1.34-4.06-3.3l-.92-1.03a7.87 7.87 0 0 1-1.03-1.28 3.7 3.7 0 0 1-.38-1c-.09-.4-.09-.82-.09-1.66V12.51c0-.84 0-1.26.09-1.65.08-.35.2-.7.38-1 .2-.36.48-.67 1.03-1.3l.92-1.02Z"
                    />
                    <path
                        fill={`url(#${uid}-c)`}
                        d="M12.07 7.89c.98-.98 1.47-1.47 2.03-1.65.5-.16 1.03-.16 1.52 0 .57.18 1.05.67 2.03 1.65l7.33 7.32c.97.98 1.46 1.46 1.64 2.03.16.5.16 1.03 0 1.52-.18.56-.67 1.05-1.64 2.03l-7.33 7.32c-.98.98-1.46 1.47-2.03 1.65-.5.16-1.03.16-1.52 0-.56-.18-1.05-.67-2.03-1.65l-4.53-4.53c-1.96-1.95-2.93-2.93-3.3-4.06a4.93 4.93 0 0 1 0-3.04c.37-1.13 1.34-2.1 3.3-4.06l4.53-4.53Z"
                    />
                    <defs>
                        <radialGradient
                            id={`${uid}-a`}
                            cx="0"
                            cy="0"
                            r="1"
                            gradientTransform="rotate(-58.14 35.5 5.08) scale(23.3731 36.5508)"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FFD580" />
                            <stop offset=".09" stopColor="#F6C592" />
                            <stop offset=".2" stopColor="#EBB6A2" />
                            <stop offset=".32" stopColor="#DFA5AF" />
                            <stop offset=".43" stopColor="#D397BE" />
                            <stop offset=".53" stopColor="#C486CB" />
                            <stop offset=".65" stopColor="#B578D9" />
                            <stop offset=".77" stopColor="#A166E5" />
                            <stop offset=".89" stopColor="#8B57F2" />
                            <stop offset="1" stopColor="#704CFF" />
                        </radialGradient>
                        <linearGradient
                            id={`${uid}-b`}
                            x1="11.49"
                            x2="16.93"
                            y1="-1.56"
                            y2="31.68"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#6D4AFF" />
                            <stop offset=".39" stopColor="#B39FFB" stopOpacity=".98" />
                            <stop offset="1" stopColor="#FFE8DB" stopOpacity=".8" />
                        </linearGradient>
                        <radialGradient
                            id={`${uid}-c`}
                            cx="0"
                            cy="0"
                            r="1"
                            gradientTransform="matrix(9.923 -15.96803 24.97081 15.51758 10.4 29.7)"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FFD580" />
                            <stop offset=".09" stopColor="#F6C592" />
                            <stop offset=".2" stopColor="#EBB6A2" />
                            <stop offset=".32" stopColor="#DFA5AF" />
                            <stop offset=".43" stopColor="#D397BE" />
                            <stop offset=".53" stopColor="#C486CB" />
                            <stop offset=".65" stopColor="#B578D9" />
                            <stop offset=".77" stopColor="#A166E5" />
                            <stop offset=".89" stopColor="#8B57F2" />
                            <stop offset="1" stopColor="#704CFF" />
                        </radialGradient>
                    </defs>
                </>
            )}

            {variant === 'with-wordmark' && (
                <>
                    <path
                        fill={`url(#${uid}-a)`}
                        d="M12.42 7.54c1.95-1.96 2.93-2.93 4.06-3.3a4.93 4.93 0 0 1 3.04 0c1.13.37 2.1 1.34 4.06 3.3l4.88 4.88c1.96 1.95 2.93 2.93 3.3 4.06.32.99.32 2.05 0 3.04-.37 1.13-1.34 2.1-3.3 4.06l-4.88 4.88c-1.95 1.96-2.93 2.93-4.06 3.3-.99.32-2.05.32-3.04 0-1.13-.37-2.1-1.34-4.06-3.3l-.92-1.03a7.87 7.87 0 0 1-1.03-1.28 3.7 3.7 0 0 1-.38-1c-.09-.4-.09-.82-.09-1.66V12.51c0-.84 0-1.26.09-1.65.08-.35.2-.7.38-1 .2-.36.48-.67 1.03-1.3l.92-1.02Z"
                    />
                    <path
                        fill={`url(#${uid}-b)`}
                        d="M12.42 7.54c1.95-1.96 2.93-2.93 4.06-3.3a4.93 4.93 0 0 1 3.04 0c1.13.37 2.1 1.34 4.06 3.3l4.88 4.88c1.96 1.95 2.93 2.93 3.3 4.06.32.99.32 2.05 0 3.04-.37 1.13-1.34 2.1-3.3 4.06l-4.88 4.88c-1.95 1.96-2.93 2.93-4.06 3.3-.99.32-2.05.32-3.04 0-1.13-.37-2.1-1.34-4.06-3.3l-.92-1.03a7.87 7.87 0 0 1-1.03-1.28 3.7 3.7 0 0 1-.38-1c-.09-.4-.09-.82-.09-1.66V12.51c0-.84 0-1.26.09-1.65.08-.35.2-.7.38-1 .2-.36.48-.67 1.03-1.3l.92-1.02Z"
                    />
                    <path
                        fill={`url(#${uid}-c)`}
                        d="M12.07 7.89c.98-.98 1.47-1.47 2.03-1.65.5-.16 1.03-.16 1.52 0 .57.18 1.05.67 2.03 1.65l7.33 7.32c.97.98 1.46 1.46 1.64 2.03.16.5.16 1.03 0 1.52-.18.56-.67 1.05-1.64 2.03l-7.33 7.32c-.98.98-1.46 1.47-2.03 1.65-.5.16-1.03.16-1.52 0-.56-.18-1.05-.67-2.03-1.65l-4.53-4.53c-1.96-1.95-2.93-2.93-3.3-4.06a4.93 4.93 0 0 1 0-3.04c.37-1.13 1.34-2.1 3.3-4.06l4.53-4.53Z"
                    />
                    <path
                        fill="var(--logo-text-product-color)"
                        d="M119.02 15.65a4.82 4.82 0 0 0-4.98 4.9c0 2.96 2.04 4.92 4.5 4.92 1.36 0 2.44-.6 3.04-1.62l.16 1.4h2.24v-4.68c0-3.04-2.12-4.92-4.96-4.92Zm0 7.6c-1.42 0-2.48-1.1-2.48-2.7s1.08-2.7 2.48-2.7c1.38 0 2.46 1.06 2.46 2.7 0 1.84-1.24 2.7-2.46 2.7Zm9.87 2.22c1.92 0 3.56-1.1 3.56-2.78 0-3.6-4.86-2.76-4.86-4.22 0-.48.42-.84 1.08-.84.68 0 1.12.38 1.22.94h2.38c-.14-1.84-1.54-2.94-3.6-2.92-2.12 0-3.42 1.26-3.42 2.82 0 3.64 4.8 2.7 4.8 4.22 0 .46-.44.8-1.16.8-.7 0-1.34-.34-1.46-1.06H125c.14 1.78 1.7 3.04 3.88 3.04Zm8.41 0c1.92 0 3.56-1.1 3.56-2.78 0-3.6-4.86-2.76-4.86-4.22 0-.48.42-.84 1.08-.84.68 0 1.12.38 1.22.94h2.38c-.14-1.84-1.54-2.94-3.6-2.92-2.12 0-3.42 1.26-3.42 2.82 0 3.64 4.8 2.7 4.8 4.22 0 .46-.44.8-1.16.8-.7 0-1.34-.34-1.46-1.06h-2.42c.14 1.78 1.7 3.04 3.88 3.04ZM109.47 11h-6.57v14.25h2.6V21.7a1.3 1.3 0 0 1 1.3-1.3h2.67a4.68 4.68 0 0 0 4.34-6.49 4.67 4.67 0 0 0-4.34-2.91Zm2.05 4.67a2.2 2.2 0 0 1-.65 1.58 2.2 2.2 0 0 1-1.57.64h-3.8v-4.43h3.8a2.22 2.22 0 0 1 2.22 2.23v-.02Z"
                    />
                    <path
                        fill="var(--logo-text-proton-color)"
                        d="M42 21.26v3.66h2.56v-3.5a1.28 1.28 0 0 1 1.28-1.29h2.62a4.6 4.6 0 0 0 4.62-4.64 4.66 4.66 0 0 0-4.62-4.64H42v4.58h2.56v-2.16h3.73a2.18 2.18 0 0 1 2.18 2.2 2.2 2.2 0 0 1-2.18 2.2h-2.71a3.51 3.51 0 0 0-2.53 1.05A3.65 3.65 0 0 0 42 21.26Zm11.47 3.66v-5.58c0-2.28 1.32-4.09 3.97-4.09.42 0 .85.04 1.26.14v2.3c-.3-.02-.56-.02-.68-.02-1.4 0-2 .64-2 1.95v5.3h-2.55Zm5.99-4.73c0-2.8 2.1-4.94 5.04-4.94a4.85 4.85 0 0 1 5.03 4.94c0 2.8-2.1 4.96-5.03 4.96a4.87 4.87 0 0 1-5.04-4.96Zm7.56 0c0-1.6-1.06-2.72-2.52-2.72-1.47 0-2.53 1.12-2.53 2.72 0 1.61 1.07 2.72 2.53 2.72 1.46 0 2.52-1.11 2.52-2.72Zm10.65 0c0-2.8 2.1-4.94 5.03-4.94a4.85 4.85 0 0 1 5.03 4.94c0 2.8-2.1 4.96-5.03 4.96a4.87 4.87 0 0 1-5.03-4.96Zm7.55 0c0-1.6-1.06-2.72-2.52-2.72s-2.53 1.12-2.53 2.72c0 1.61 1.07 2.72 2.53 2.72 1.46 0 2.52-1.11 2.52-2.72Zm3.83 4.73v-5.38c0-2.5 1.59-4.29 4.41-4.29 2.8 0 4.4 1.8 4.4 4.3v5.37h-2.53v-5.18c0-1.39-.62-2.25-1.87-2.25-1.24 0-1.86.86-1.86 2.25v5.18h-2.55ZM76.9 17.49h-2.74v3.53c0 1.23.44 1.79 1.7 1.79.12 0 .42 0 .8-.02v2.07c-.52.14-.98.23-1.48.23-2.12 0-3.57-1.3-3.57-3.73v-3.87h-1.7v-2.04h.42a1.3 1.3 0 0 0 .9-.37 1.3 1.3 0 0 0 .38-.91v-1.92h2.55v3.2h2.74v2.04Z"
                    />
                    <defs>
                        <radialGradient
                            id={`${uid}-a`}
                            cx="0"
                            cy="0"
                            r="1"
                            gradientTransform="rotate(-58.14 35.5 5.08) scale(23.3731 36.5508)"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FFD580" />
                            <stop offset=".09" stopColor="#F6C592" />
                            <stop offset=".2" stopColor="#EBB6A2" />
                            <stop offset=".32" stopColor="#DFA5AF" />
                            <stop offset=".43" stopColor="#D397BE" />
                            <stop offset=".53" stopColor="#C486CB" />
                            <stop offset=".65" stopColor="#B578D9" />
                            <stop offset=".77" stopColor="#A166E5" />
                            <stop offset=".89" stopColor="#8B57F2" />
                            <stop offset="1" stopColor="#704CFF" />
                        </radialGradient>
                        <linearGradient
                            id={`${uid}-b`}
                            x1="11.49"
                            x2="16.93"
                            y1="-1.56"
                            y2="31.68"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#6D4AFF" />
                            <stop offset=".39" stopColor="#B39FFB" stopOpacity=".98" />
                            <stop offset="1" stopColor="#FFE8DB" stopOpacity=".8" />
                        </linearGradient>
                        <radialGradient
                            id={`${uid}-c`}
                            cx="0"
                            cy="0"
                            r="1"
                            gradientTransform="matrix(9.923 -15.96803 24.97081 15.51758 10.4 29.7)"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FFD580" />
                            <stop offset=".09" stopColor="#F6C592" />
                            <stop offset=".2" stopColor="#EBB6A2" />
                            <stop offset=".32" stopColor="#DFA5AF" />
                            <stop offset=".43" stopColor="#D397BE" />
                            <stop offset=".53" stopColor="#C486CB" />
                            <stop offset=".65" stopColor="#B578D9" />
                            <stop offset=".77" stopColor="#A166E5" />
                            <stop offset=".89" stopColor="#8B57F2" />
                            <stop offset="1" stopColor="#704CFF" />
                        </radialGradient>
                    </defs>
                </>
            )}

            {variant === 'wordmark-only' && (
                <>
                    <path
                        d="M249.602 35.9127C246.947 35.9127 244.746 35.2071 242.999 33.7959C241.252 32.3511 240.311 30.4695 240.177 28.1511H245.368C245.502 29.2263 245.956 30.0663 246.729 30.6711C247.535 31.2759 248.493 31.5783 249.602 31.5783C250.744 31.5783 251.618 31.3431 252.222 30.8727C252.827 30.4023 253.13 29.8143 253.13 29.1087C253.13 28.3023 252.693 27.6471 251.819 27.1431C250.979 26.6391 249.938 26.2191 248.694 25.8831C247.485 25.5471 246.275 25.1439 245.066 24.6735C243.856 24.2031 242.815 23.4303 241.941 22.3551C241.101 21.2799 240.681 19.9191 240.681 18.2727C240.681 16.3575 241.42 14.7111 242.898 13.3335C244.41 11.9559 246.46 11.2671 249.047 11.2671C251.534 11.2671 253.566 11.9223 255.146 13.2327C256.725 14.5431 257.632 16.3743 257.867 18.7263H252.676C252.508 17.7519 252.105 16.9959 251.466 16.4583C250.862 15.8871 250.055 15.6015 249.047 15.6015C248.107 15.6015 247.334 15.8535 246.729 16.3575C246.124 16.8615 245.822 17.4999 245.822 18.2727C245.822 18.9783 246.124 19.5663 246.729 20.0367C247.367 20.5071 248.14 20.8599 249.047 21.0951C249.988 21.3303 250.996 21.6495 252.071 22.0527C253.147 22.4223 254.138 22.8423 255.045 23.3127C255.986 23.7831 256.758 24.5223 257.363 25.5303C258.002 26.5047 258.321 27.6975 258.321 29.1087C258.321 31.1247 257.481 32.7711 255.801 34.0479C254.121 35.2911 252.054 35.9127 249.602 35.9127Z"
                        fill="var(--logo-text-proton-color)"
                    />
                    <path
                        d="M227.603 35.9127C224.949 35.9127 222.748 35.2071 221 33.7959C219.253 32.3511 218.313 30.4695 218.178 28.1511H223.369C223.504 29.2263 223.957 30.0663 224.73 30.6711C225.536 31.2759 226.494 31.5783 227.603 31.5783C228.745 31.5783 229.619 31.3431 230.224 30.8727C230.828 30.4023 231.131 29.8143 231.131 29.1087C231.131 28.3023 230.694 27.6471 229.82 27.1431C228.981 26.6391 227.939 26.2191 226.696 25.8831C225.486 25.5471 224.276 25.1439 223.067 24.6735C221.857 24.2031 220.816 23.4303 219.942 22.3551C219.102 21.2799 218.682 19.9191 218.682 18.2727C218.682 16.3575 219.421 14.7111 220.9 13.3335C222.412 11.9559 224.461 11.2671 227.048 11.2671C229.535 11.2671 231.567 11.9223 233.147 13.2327C234.726 14.5431 235.633 16.3743 235.868 18.7263H230.677C230.509 17.7519 230.106 16.9959 229.468 16.4583C228.863 15.8871 228.056 15.6015 227.048 15.6015C226.108 15.6015 225.335 15.8535 224.73 16.3575C224.125 16.8615 223.823 17.4999 223.823 18.2727C223.823 18.9783 224.125 19.5663 224.73 20.0367C225.369 20.5071 226.141 20.8599 227.048 21.0951C227.989 21.3303 228.997 21.6495 230.072 22.0527C231.148 22.4223 232.139 22.8423 233.046 23.3127C233.987 23.7831 234.76 24.5223 235.364 25.5303C236.003 26.5047 236.322 27.6975 236.322 29.1087C236.322 31.1247 235.482 32.7711 233.802 34.0479C232.122 35.2911 230.056 35.9127 227.603 35.9127Z"
                        fill="var(--logo-text-proton-color)"
                    />
                    <path
                        d="M199.138 35.9127C196.047 35.9127 193.392 34.8039 191.175 32.5863C188.991 30.3687 187.899 27.3783 187.899 23.6151C187.899 19.8183 188.991 16.8111 191.175 14.5935C193.392 12.3759 196.047 11.2671 199.138 11.2671C200.885 11.2671 202.464 11.6367 203.876 12.3759C205.287 13.0815 206.396 14.1063 207.202 15.4503L207.706 11.7711H212.292V35.4087H207.605L207.202 31.7799C205.522 34.5351 202.834 35.9127 199.138 35.9127ZM193.191 23.6151C193.191 25.9335 193.863 27.7815 195.207 29.1591C196.584 30.5031 198.264 31.1751 200.247 31.1751C202.128 31.1751 203.758 30.5031 205.136 29.1591C206.513 27.7815 207.202 25.9335 207.202 23.6151C207.202 21.3639 206.496 19.5495 205.085 18.1719C203.707 16.7607 202.095 16.0551 200.247 16.0551C198.264 16.0551 196.584 16.7439 195.207 18.1215C193.863 19.4991 193.191 21.3303 193.191 23.6151Z"
                        fill="var(--logo-text-proton-color)"
                    />
                    <path
                        d="M160.833 35.4087V1.0863H174.592C177.717 1.0863 180.321 2.0607 182.404 4.00951C184.521 5.9583 185.58 8.44471 185.58 11.4687C185.58 14.4927 184.521 16.9959 182.404 18.9783C180.321 20.9271 177.717 21.9015 174.592 21.9015H166.226V35.4087H160.833ZM166.226 16.8615H173.584C175.701 16.8615 177.314 16.3575 178.423 15.3495C179.532 14.3415 180.086 13.0479 180.086 11.4687C180.086 9.8895 179.532 8.61272 178.423 7.63831C177.348 6.63031 175.768 6.12631 173.685 6.12631H166.226V16.8615Z"
                        fill="var(--logo-text-proton-color)"
                    />
                    <path
                        d="M120.804 21.6495C120.804 18.5583 121.745 16.0551 123.627 14.1399C125.509 12.2247 128.079 11.2671 131.338 11.2671C134.597 11.2671 137.168 12.2247 139.049 14.1399C140.931 16.0551 141.872 18.5583 141.872 21.6495V35.4087H136.58V22.0527C136.58 20.1711 136.126 18.7095 135.219 17.6679C134.345 16.5927 133.052 16.0551 131.338 16.0551C129.624 16.0551 128.314 16.5927 127.407 17.6679C126.533 18.7095 126.096 20.1711 126.096 22.0527V35.4087H120.804V21.6495Z"
                        fill="var(--logo-text-proton-color)"
                    />
                    <path
                        d="M103.589 35.9127C100.061 35.9127 97.1208 34.7367 94.7689 32.3847C92.417 30.0327 91.2409 27.1095 91.2409 23.6151C91.2409 20.1207 92.417 17.1975 94.7689 14.8455C97.1208 12.4599 100.061 11.2671 103.589 11.2671C107.15 11.2671 110.107 12.4599 112.459 14.8455C114.811 17.1975 115.987 20.1207 115.987 23.6151C115.987 27.1095 114.811 30.0327 112.459 32.3847C110.107 34.7367 107.15 35.9127 103.589 35.9127ZM96.5329 23.6151C96.5329 25.7991 97.1881 27.6135 98.4985 29.0583C99.8424 30.4695 101.539 31.1751 103.589 31.1751C105.638 31.1751 107.335 30.4695 108.679 29.0583C110.023 27.6135 110.695 25.7991 110.695 23.6151C110.695 21.3975 110.023 19.5831 108.679 18.1719C107.335 16.7271 105.638 16.0047 103.589 16.0047C101.539 16.0047 99.8424 16.7271 98.4985 18.1719C97.1881 19.5831 96.5329 21.3975 96.5329 23.6151Z"
                        fill="var(--logo-text-proton-color)"
                    />
                    <path
                        d="M84.2303 35.7615C81.7438 35.7615 79.7108 35.0223 78.1319 33.5439C76.586 32.0319 75.8135 29.9487 75.8135 27.2943V16.1559H71.5295V11.7711H75.7631V3.85834H81.1055V11.7711H88.0103V16.1559H81.1055V26.6391C81.1055 28.2183 81.4583 29.3439 82.1639 30.0159C82.903 30.6543 84.0791 30.9735 85.6919 30.9735C86.2628 30.9735 86.8846 30.9567 87.5567 30.9231V35.3079C86.3806 35.6103 85.2718 35.7615 84.2303 35.7615Z"
                        fill="var(--logo-text-proton-color)"
                    />
                    <path
                        d="M56.8843 35.9127C53.3563 35.9127 50.4162 34.7367 48.0643 32.3847C45.7124 30.0327 44.5363 27.1095 44.5363 23.6151C44.5363 20.1207 45.7124 17.1975 48.0643 14.8455C50.4162 12.4599 53.3563 11.2671 56.8843 11.2671C60.4458 11.2671 63.4028 12.4599 65.7547 14.8455C68.1066 17.1975 69.2827 20.1207 69.2827 23.6151C69.2827 27.1095 68.1066 30.0327 65.7547 32.3847C63.4028 34.7367 60.4458 35.9127 56.8843 35.9127ZM49.8283 23.6151C49.8283 25.7991 50.4835 27.6135 51.7939 29.0583C53.1378 30.4695 54.8348 31.1751 56.8843 31.1751C58.9338 31.1751 60.6308 30.4695 61.9747 29.0583C63.3186 27.6135 63.9907 25.7991 63.9907 23.6151C63.9907 21.3975 63.3186 19.5831 61.9747 18.1719C60.6308 16.7271 58.9338 16.0047 56.8843 16.0047C54.8348 16.0047 53.1378 16.7271 51.7939 18.1719C50.4835 19.5831 49.8283 21.3975 49.8283 23.6151Z"
                        fill="var(--logo-text-proton-color)"
                    />
                    <path
                        d="M29.167 20.8935C29.167 17.9703 29.9902 15.6351 31.6366 13.8879C33.3166 12.1407 35.5678 11.2671 38.3904 11.2671C39.2303 11.2671 40.171 11.3847 41.2128 11.6199V16.4583C40.9104 16.4247 40.3056 16.4079 39.3984 16.4079C37.7518 16.4079 36.5087 16.8279 35.6686 17.6679C34.8622 18.5079 34.459 19.8855 34.459 21.8007V35.4087H29.167V20.8935Z"
                        fill="var(--logo-text-proton-color)"
                    />
                    <path
                        d="M0 35.4087V1.0863H13.7592C16.884 1.0863 19.488 2.0607 21.5712 4.00951C23.688 5.9583 24.7464 8.44471 24.7464 11.4687C24.7464 14.4927 23.688 16.9959 21.5712 18.9783C19.488 20.9271 16.884 21.9015 13.7592 21.9015H5.3928V35.4087H0ZM5.3928 16.8615H12.7512C14.868 16.8615 16.4808 16.3575 17.5896 15.3495C18.6984 14.3415 19.2528 13.0479 19.2528 11.4687C19.2528 9.8895 18.6984 8.61272 17.5896 7.63831C16.5144 6.63031 14.9352 6.12631 12.852 6.12631H5.3928V16.8615Z"
                        fill="var(--logo-text-proton-color)"
                    />
                </>
            )}
        </LogoBase>
    );
};

export default PassLogo;
