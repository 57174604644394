// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { AmountAndCurrency, ChargeablePaymentParameters, ChargeablePaymentToken } from '../interface';

type UpdateHandler<T> = (stateDiff: Partial<T>) => void;

export class InvalidDataError extends Error {
    constructor(message?: string) {
        super(message);
        this.name = 'InvalidDataError';

        // Flag to ignore this error and not send it to Sentry
        (this as any).ignore = true;
    }
}

/**
 * Base class for payment processors. Defines the interfaces for the most important methods:
 * `fetchPaymentToken` and `verifyPaymentToken`. Defines a concept of state, which is implemented by the subclasses.
 * Provides a set of common utils to update the state and subscribe to state changes.
 */
export abstract class PaymentProcessor<T = any> {
    private updatedHandlers: {
        id: string;
        handler: UpdateHandler<T>;
    }[] = [];

    constructor(
        protected state: T,
        public amountAndCurrency: AmountAndCurrency
        // public onTokenIsChargeable?: (
        //     data: ChargeablePaymentParameters | ChargeableV5PaymentParameters
        // ) => Promise<unknown>
    ) {}

    /**
     * Fetches the payment token. That's a token generated by the Proton's backend. The return type acknowledges that
     * result might be different depending on the payment processor and input parameters. For example, if a credit card
     * doesn't require 3DS, then the token becomes chargeable right away. If it does, then the token is non-chargeable,
     * and requires user confirmation. Sometimes the token is even `null`, for example, if the Amount is 0.
     * That might be the case, for example, when user has enough credits to make subscription.
     */
    abstract fetchPaymentToken(): Promise<unknown>;

    /**
     * An important detail is that this method returns {@link ChargeablePaymentParameters} instead of
     * {@link ChargeablePaymentToken}. The crucial difference is that sometimes the payment parameters can be used for
     * a certain operation even without a payment token. Like in the case of a subscription when user alread has enough
     * credits.
     * This method is supposed to be called after `fetchPaymentToken`.
     */
    abstract verifyPaymentToken(): Promise<unknown>;

    updateState(state: Partial<T>) {
        this.state = { ...this.state, ...state };
        for (const { handler } of this.updatedHandlers) {
            handler(state);
        }
    }

    onStateUpdated(handler: UpdateHandler<T>, { initial = false } = {}) {
        const id = Math.random().toString(36).slice(2, 11);

        this.updatedHandlers.push({
            id,
            handler,
        });

        if (initial) {
            handler(this.state);
        }

        return id;
    }

    destroy() {
        this.clearHandlers();
    }

    clearHandlers() {
        this.updatedHandlers = [];
    }

    removeHandler(idOrHandler: string | UpdateHandler<T>) {
        if (typeof idOrHandler === 'string') {
            this.updatedHandlers = this.updatedHandlers.filter(({ id }) => id !== idOrHandler);
        } else {
            this.updatedHandlers = this.updatedHandlers.filter(({ handler }) => handler !== idOrHandler);
        }
    }
}
